<template>
  <div class="row">
    <div class="col-12">
      <b-form-group label="Nombre" label-for="name">
        <b-form-input id="name" v-model="form.name" placeholder="Ingrese un nombre" :required="true" />
      </b-form-group>
      <b-form-group label="Estatus" label-for="status">
       <input-switch v-model="form.verified"></input-switch>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-end">
          <button-store />
        </div>
      </b-form-group>
    </div>
  </div>
</template>


<script>
import {
  BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import InputSwitch from "@/components/InputSwitch";
import ButtonStore from "@/components/ButtonStore";

export default {
  components: {
    ButtonStore,
    InputSwitch,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: this.value,
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true,
    },
    value(value) {
      this.form = value
    },
  },
}
</script>

<style scoped>

</style>
