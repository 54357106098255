<template>
  <div>
    <b-card>
      <!-- search input -->
      <div class="w-100 d-flex justify-content-between">
        <div>
          <Actions ref="actions">
            <template v-slot:options>
              <b-dropdown-item @click="openCreateModal">Crear</b-dropdown-item>
              <b-dropdown-item @click="openImportModal">
                Importar
              </b-dropdown-item>
            </template>
          </Actions>
        </div>
        <div>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Buscar</label>
                <b-form-input v-model="searchTerm" placeholder="Buscar" type="text" class="d-inline-block"/>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
      <vue-good-table :columns="columns" :rows="rows" :search-options="{ enabled: true, externalQuery: searchTerm }" :pagination-options="{ enabled: true, perPage:pageLength }">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'verified'">
            <span>
              <input-switch @onChange="changeVerifiedStatus(props.row)" v-model="props.row.verified" />
            </span>
          </span>
          <span v-else-if="props.column.field === 'manual'">
            <span>
              <feather-icon v-if="props.row.manual" icon="CheckCircleIcon" size="20"/>
            </span>
          </span>
          <span v-else-if="props.column.field === 'image'">
            <img style="max-width: 50px; cursor: pointer" v-if="isNotDefaultImage(props.row)" @click="openImageModal(props.row)" :src="props.row.photo_url" lazy alt="">
            <b-button v-else  @click="openImageModal(props.row)" variant="flat-success" class="btn-icon rounded-circle">
              <feather-icon icon="CameraIcon" />
            </b-button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrar
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> de {{ props.total }} registros </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <!-- MODAL PARA IMPORTAR UN ARCHIVO -->
    <import @onFinished="getAreas" ref="importComponent"/>
    <create @onFinished="getAreas" ref="formCreateComponent"/>
    <form-image @onFinished="getAreas" ref="formImageComponent" />
  </div>
</template>

<script>
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BDropdownItem, BCardText, BFormCheckbox,BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import AreaService from "@/services/AreaService";
import InputSwitch from "@/components/InputSwitch";
import Import from "@/views/catalogs/area/Import";
import Create from "@/views/catalogs/area/Create";
import FormImage from "@/views/catalogs/area/FormImage";

export default {
  components: {
    Create,
    Import,
    InputSwitch,
    VueGoodTable,
    BCard,
    BFormGroup,
    BFormInput,
    BDropdownItem,
    BPagination,
    BFormSelect,
    BBadge,
    BCardText,
    BFormCheckbox,
    BButton,
    FormImage
  },
  data() {
    return {
      searchTerm: '',
      pageLength: 10,
      columns: [
        {
          label: 'Nombre',
          field: 'name',
          thClass: 'custom-th-class',
        },
        {
          label: 'Verificado',
          field: 'verified',
          sortable: false,
          thClass: 'custom-th-class',
          tdClass: 'text-center'
        },
        {
          label: 'Imagen',
          field: 'image',
          thClass: 'custom-th-class',
          tdClass: 'text-center'
        },
        {
          label: 'Fecha',
          field: 'date',
          thClass: 'custom-th-class',
          tdClass: 'text-center'
        },
      ],
      rows: [],
    }
  },
  created() {
    this.getAreas();
  },
  methods: {
    getAreas(){
      this.rows = []
      this.$toggleLoading()
      AreaService.get().then(response => {
        this.rows = response.data.data.item;
      }).then(() => this.$toggleLoading())
    },
    changeVerifiedStatus(item){
      AreaService.update({ id: item.id, value: item.verified })
          .then(response => {
            this.$showSuccess("Guardado correctamente")
          })
          .catch(error => {
            this.$showError(error.response.data.message);
          })
    },
    openImportModal(){
      this.$refs.importComponent.open();
    },

    openImageModal(area){
      this.$refs.formImageComponent.open(area);
    },
    openCreateModal(){
      this.$refs.formCreateComponent.open();
    },
    isNotDefaultImage(area){
      return (area.photo_url !== "http://localhost/img/logo-app.png");
    }
  }
}
</script>

<style>

</style>

