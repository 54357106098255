<template>
  <Modal title="Importar Archivo" ref="modal">
    <template v-slot:body>
      <div class="w-100">
        <InputFile
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @success="setFile"/>
      </div>
    </template>
    <template v-slot:footer>
      <div class="w-100 border-b pb-2 pt-1">
        <div class="d-flex justify-content-end">
          <b-button variant="success" @click="uploadFile">
            Importar
          </b-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import AreaService from "@/services/AreaService";
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton
  },
  methods: {
    uploadFile(){
      if(!this.file){
        this.$showError("Por favor selecciona un archivo")
        return false;
      }else{
        let formData = new FormData();
        formData.append("file",this.file);
        AreaService.importData(formData).then( (response) => {
          this.$showSuccess("El archivo se guardó con Exito!");
          this.$refs.modal.close();
          this.$emit('onFinished');
        }).catch((error)=>{

        })
      }
    },

    setFile(file){
      this.file = file;
    },

    open(){
      this.$refs.modal.launch();
    }
  }
}
</script>

<style scoped>

</style>
