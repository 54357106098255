<template>
  <Modal title="Crear Area" ref="modal_create_area">
    <template v-slot:body>
      <form @submit.prevent="store">
        <form-area v-model="form" />
      </form>
    </template>
  </Modal>
</template>

<script>
import FormArea from "@/views/catalogs/area/FormArea";
import AreaService from "@/services/AreaService";
export default {
  components: {FormArea},
  data() {
    return {
      form: {
        name: null,
        verified: true,
      }
    }
  },
  methods:{
    open(){
      this.form = { name: null, verified: true }
      this.$refs.modal_create_area.launch();
    },
     store(){
      AreaService.store(this.form).then(response => {
        this.$showSuccess("Se ha guardado correctamente");
        this.$refs.modal_create_area.close();
        this.$emit('onFinished');
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
