<template>
  <Modal title="Imagen" ref="img_modal">
    <template v-slot:body>
      <form @submit.prevent="store">
        <div class="row">
          <div class="col-12">
            <input-drop-zone v-model="image" folder="areas/images" />
          </div>
          <div class="col-12 mt-2 text-right">
            <button-store :disabled="image == null" />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import InputDropZone from "@/components/InputDropZone";
import ButtonStore from "@/components/ButtonStore";
import AreaService from "@/services/AreaService";

export default {
  components: {ButtonStore, InputDropZone},
  data() {
    return {
      image : null,
      id: null
    }
  },
  methods: {
    open(item) {
      this.id = item.id
      this.$refs.img_modal.launch();
    },
    store() {
      AreaService.updateSingle(this.id, {photo_url : this.image})
          .then(() => {
            this.$refs.img_modal.close()
            this.$showSuccess("Guardado correctamente")
            this.$emit('onFinished')
          }).catch( () => {
            this.$showError("Error al guardar la imagen");
          });
    },
  }
}
</script>

<style scoped>

</style>
